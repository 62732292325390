/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";
import RequestController from "@components/utilities/request_controller"

export class Controller extends BaseController {
  static values = {
    url: String
  }

  connect() {
    const request = new RequestController();
    request.fetchJson({ 
      url: this.urlValue
    })
    .then(response => {
      this.skillCodes = response;
      this.updateSkillCode(this.element.value);
    })

    this.boundUpdateSkillCode = (event) => this.updateSkillCode(event.target.value);
    this.element.addEventListener('change', this.boundUpdateSkillCode);
    
  }

  updateSkillCode(employeeId) {
    const skillCodeInput = document.getElementById('bill_skill_code');
    if (skillCodeInput && this.skillCodes) {
      skillCodeInput.value = this.skillCodes[employeeId] || '';
    }
  }

  disconnect() {
    this.element.removeEventListener('change', this.boundUpdateSkillCode);
  }
}