/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";
// import RequestController from "@components/utilities/request_controller"

export class Controller extends BaseController {
  static targets = ["facilityNumber", "locationCode", "functionalCenter"]

  connect() {
    // Add data-target attributes to inputs
    this.setTargets()

    // Bind the event handler once
    this.validateFieldsHandler = () => this.validateFields()

    // Add event listeners
    this.facilityNumberTarget.addEventListener('input', this.validateFieldsHandler)
    this.facilityNumberTarget.addEventListener('change', this.validateFieldsHandler)

    this.locationCodeTarget.addEventListener('change', this.validateFieldsHandler)
    
    this.functionalCenterTarget.addEventListener('input', this.validateFieldsHandler)
    this.functionalCenterTarget.addEventListener('change', this.validateFieldsHandler)
    
     // Add blur listener to parent element
    this.element.addEventListener('blur', this.validateFieldsHandler, true) // true for capture phase so we catch all blur events from child elements

    // Initial check
    this.validateFields()
  }

  validateFields() {
    const hasFacility = this.facilityNumberTarget.value.trim().length > 0
    const hasLocation = this.locationCodeTarget.value.trim().length > 0
    const hasFunction = this.functionalCenterTarget.value.trim().length > 0

    const hasWarning = (hasFacility && hasLocation) || 
                      (hasLocation && hasFunction)

    if (hasWarning) {
      this.element.classList.add('warning')
    } else {
      this.element.classList.remove('warning')
    }
  }

  setTargets() {
    this.element.querySelector('#bill_facility_number')
      .setAttribute('data-bills-ui--bills-form--location-form-item-layout-target', 'facilityNumber')
    this.element.querySelector('#bill_location_code')
      .setAttribute('data-bills-ui--bills-form--location-form-item-layout-target', 'locationCode')
    this.element.querySelector('#bill_functional_center')
      .setAttribute('data-bills-ui--bills-form--location-form-item-layout-target', 'functionalCenter')
  }

  disconnect() {
    // Clean up event listeners with the same function reference
    this.facilityNumberTarget.removeEventListener('input', this.validateFieldsHandler)
    this.facilityNumberTarget.removeEventListener('change', this.validateFieldsHandler)
    
    this.locationCodeTarget.removeEventListener('change', this.validateFieldsHandler)
    
    this.functionalCenterTarget.removeEventListener('input', this.validateFieldsHandler)
    this.functionalCenterTarget.removeEventListener('change', this.validateFieldsHandler)

    this.element.removeEventListener('blur', this.validateFieldsHandler, true)
  }
}
