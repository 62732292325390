import { consumer, isSubscribed } from "../consumer"
import { markDivAsConnected, markDivAsDisconnected, startLongPoller } from "../utility_functions";

document.addEventListener('subscribeToRoom', (event) => {
  const { roomId, roomSgid } = event.detail;
  subscribeToRoom(roomId, roomSgid);
});

function subscribeToRoom(roomId, roomSgid) {
  let channelName = getChannelName(roomId);
  const subscriptionObject = { channel: "EmrChannels::RoomChannel", channel_name: channelName };
  const localRoomWidgetId = roomWidgetId(roomId);
  const indicatorId = `${localRoomWidgetId}-indicator`;

  let connected = false;
  let longPoller;

  if (!roomId) {
    return;
  } else if (isSubscribed(subscriptionObject)) {
    longPoller = markDivAsConnected(indicatorId, localRoomWidgetId, longPoller);
  } else {
    consumer.subscriptions.create(
      subscriptionObject,
      {
        connected() {
          connected = true;
          longPoller = markDivAsConnected(indicatorId, localRoomWidgetId, longPoller);
          // If the room was idle for a while we want to refetch changes in the interim
          const roomWidgetElement = document.getElementById(localRoomWidgetId);
          const lastFetched = parseInt(roomWidgetElement.getAttribute('data-last-fetched'), 10);
          const now = new Date().getTime();
          const threshold = 30 * 1000; // 30s

          if (!lastFetched || now - lastFetched > threshold) {
            console.log("refetching for", roomSgid);
            refetch(roomId, roomSgid);
          }
        },
  
        disconnected() {
          markDivAsDisconnected(indicatorId, localRoomWidgetId);
        },

        rejected() {
          startLongPoller(longPoller, () => refetch(roomId, roomSgid));
        },
  
        received(data) {
          console.log("RECEIVED ROOM DATA: ", data)
          if (data.action === "create") {
            createAppointment(roomId, data);
          } else if (data.action === "destroy") {
            deleteAppointment(roomId, data);
          }
        },

      }
    );

    setTimeout(() => {
      if (!connected) {
        // Connection hasn't been established within 60 seconds
        console.log("Connection timeout. Starting long poller...");
        startLongPoller(longPoller, () => refetch(roomId, roomSgid));
      }
    }, 60000);
  }
}

function createAppointment(roomId, data) {
  const roomWidgetElement = document.getElementById(roomWidgetId(roomId));
  if (!roomWidgetElement) {
    console.error(`Room widget with id ${roomId} not found`);
    return;
  }

  const roomedAppointmentsIndex = roomWidgetElement.querySelector('.roomed-appointments-index')
  if (!roomedAppointmentsIndex) {
    console.error(`Room widget with id ${roomId} does not have a .roomed-appointments-index`);
    return;
  }

  const appointmentEl = roomWidgetElement.querySelector(`[data-appointment-id="${data.appointment_id}"]`);
  if (!appointmentEl) {
    roomedAppointmentsIndex.insertAdjacentHTML('beforeend', data.html);
  } else {
    appointmentEl.outerHTML = data.html;
  }
}

function deleteAppointment(roomId, data) {
  const roomWidgetElement = document.getElementById(roomWidgetId(roomId));
  if (!roomWidgetElement) {
    console.error(`Room widget with id ${roomId} not found`);
    return;
  }
  const appointmentEl = roomWidgetElement.querySelector(`[data-appointment-id="${data.appointment_id}"]`);
  if (appointmentEl) {
    appointmentEl.remove();
  }
}

function refreshWidget(roomId, data) {
  const roomWidgetElement = document.getElementById(roomWidgetId(roomId));
  if (!roomWidgetElement) {
    console.error(`Room widget with id ${roomId} not found`);
    return;
  }
  roomWidgetElement.outerHTML = data.html;
  roomWidgetElement.setAttribute('data-last-fetched', new Date().getTime());
}

function roomWidgetId(roomId) {
  return `room-widget-${roomId}`;
}

function refetch(roomId, roomSgid) {
  console.log(`Refetching room ${roomId} data due to long poller`);
  fetch(`/schedule_view/rooms/${roomSgid}`)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      refreshWidget(roomId, data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
};

function getChannelName(roomId) {
  return `room_widget_${roomId}`;
}
