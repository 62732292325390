import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static values = {
    duration: Number
  }

  connect() {
    requestAnimationFrame(() => {
      this.fadeIn();
    })
    if (this.durationValue && this.durationValue < 0) {
      // do nothing. Keep message forever if durationValue is negative
      return;
    } else if (this.durationValue && this.durationValue > 0) {
      setTimeout(() => {
        this.fadeOut();
      }, this.durationValue + 2000);
    } else {
      setTimeout(() => {
        this.fadeOut();
      }, 6000);
    }
  }

  fadeIn() {
    this.element.classList.add('fade-in');
  }

  fadeOut() {
    setTimeout(() => {
      this.element.classList.remove('fade-in');
    }, 1000);
    this.element.classList.add('fade-out');
  }

}
