/**
Add a Stimulus controller for this component.
It will automatically registered and its name will be available
via component-name

You have to export export class Controller extends BaseController 
to use index.js as a stimulus controller. If you need to use a 
stimulus controller by naming the conventional way 
(i.e. the file ends in _controller.js) you need to export default 
like the following so that the controller is properly registered:

import { Controller } from "@hotwired/stimulus"; 

export default class extends Controller { 
  ... 
} 
 */

import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ['colorField', 'showInUiField', 'selectOptionsDiv', 'valueTypeField'];

  connect() {
    this.valueTypeFieldTargetConnected();
    this.toggleSelectOptions();
  }

  disconnect() {
    this.valueTypeFieldTargetDisconnected();
  }

  // Add a value type change handler
  valueTypeFieldTargetConnected() {
    this.valueTypeFieldTarget.addEventListener('change', this.toggleSelectOptions.bind(this));
  }

  valueTypeFieldTargetDisconnected() {
    this.valueTypeFieldTarget.removeEventListener('change', this.toggleSelectOptions.bind(this));
  }

  toggleSelectOptions() {
    const isSelect = this.valueTypeFieldTarget.value === 'Select';
    if (isSelect) {
      this.selectOptionsDivTarget.classList.remove('hidden-field');
    } else {
      this.selectOptionsDivTarget.classList.add('hidden-field');
    }
  }

}
