import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = [
    'registeredTagId',
    'dateValueForm',
    'dateValueField',
    'numberValueForm',
    'numberValueField',
    'stringValueForm',
    'stringValueField',
    'endDateForm',
    'endDateField'
  ];

  connect() {
    this.addRegisteredTagIdHooks();
    this.showValueForms();
  }

  disconnect() {
    this.removeRegisteredTagIdHooks();
  }

  addRegisteredTagIdHooks() {
    if (this.registeredTagIdTarget) {
      this.registeredTagIdTarget.addEventListener('change', () => {
        this.showValueForms();
      });
    }
  }

  removeRegisteredTagIdHooks() {
    if (this.registeredTagIdTarget) {
      this.registeredTagIdTarget.removeEventListener('change', () => {
        this.showValueForms();
      });
    }
  }

  showValueForms() {
    const valueType = this.setValueType();
    const showEndDate = this.shouldShowEndDate();
    const selectOptions = this.getSelectOptions();

    // Handle end date visibility
    if (showEndDate) {
      this.showEndDateField();
    } else {
      this.hideEndDateField();
    }

    // Handle value type fields
    if (valueType === 'Date') {
      this.showDateValueField();
      this.hideNumberValueField();
      this.hideStringValueField();
    } else if (valueType === 'String' || valueType === 'Select') {
      this.showStringValueField(valueType === 'Select' ? selectOptions : null);
      this.hideNumberValueField();
      this.hideDateValueField();
    } else if (valueType === 'Number') {
      this.showNumberValueField();
      this.hideDateValueField();
      this.hideStringValueField();
    } else {
      this.hideNumberValueField();
      this.hideDateValueField();
      this.hideStringValueField();
    }
  }

  showDateValueField() {
    if (this.dateValueFormTarget) {
      this.dateValueFormTarget.classList.remove('hidden-field');
      DatePicker.init();
    }
  }

  hideDateValueField() {
    if (this.dateValueFormTarget) {
      this.dateValueFormTarget.classList.add('hidden-field');
    }
    if (this.dateValueFieldTarget) {
      this.dateValueFieldTarget.value = null;
    }
  }

  showStringValueField(selectOptions = null) {
    if (this.stringValueFormTarget) {
      this.stringValueFormTarget.classList.remove('hidden-field');
      
      // Convert between text field and select based on options
      if (selectOptions && selectOptions.length > 0) {
        this.convertToSelect(selectOptions);
      } else {
        this.convertToTextField();
      }
    }
  }

  convertToSelect(options) {
    const currentElement = this.stringValueFieldTarget;
    const currentValue = currentElement.value;
    const select = document.createElement('select');
    
    // Copy all existing attributes
    Array.from(currentElement.attributes).forEach(attr => {
      select.setAttribute(attr.name, attr.value);
    });
    
    // Add options
    options.forEach(option => {
      const optionElement = document.createElement('option');
      optionElement.value = option;
      optionElement.textContent = option;
      if (option === currentValue) {
        optionElement.selected = true;
      }
      select.appendChild(optionElement);
    });
    
    // Replace the text field with the select
    currentElement.replaceWith(select);
  }

  convertToTextField() {
    const currentElement = this.stringValueFieldTarget;
    if (currentElement.tagName.toLowerCase() === 'select') {
      const currentValue = currentElement.value;
      const input = document.createElement('input');
      
      // Copy all existing attributes
      Array.from(currentElement.attributes).forEach(attr => {
        input.setAttribute(attr.name, attr.value);
      });
      
      // Set specific attributes for text input
      input.type = 'text';
      input.value = currentValue;
      
      // Replace the select with the text field
      currentElement.replaceWith(input);
    }
  }

  hideStringValueField() {
    if (this.stringValueFormTarget) {
      this.stringValueFormTarget.classList.add('hidden-field');
    }
    if (this.stringValueFieldTarget) {
      this.stringValueFieldTarget.value = null;
    }
  }

  showNumberValueField() {
    if (this.numberValueFormTarget) {
      this.numberValueFormTarget.classList.remove('hidden-field');
    }
  }

  hideNumberValueField() {
    if (this.numberValueFormTarget) {
      this.numberValueFormTarget.classList.add('hidden-field');
    }
    if (this.numberValueFieldTarget) {
      this.numberValueFieldTarget.value = null;
    }
  }

  showEndDateField() {
    if (this.endDateFormTarget) {
      this.endDateFormTarget.classList.remove('hidden-field');
      DatePicker.init();
    }
  }

  hideEndDateField() {
    if (this.endDateFormTarget) {
      this.endDateFormTarget.classList.add('hidden-field');
    }
    if (this.endDateFieldTarget) {
      this.endDateFieldTarget.value = null;
    }
  }

  setValueType() {
    if (this.registeredTagIdTarget) {
      return $(this.registeredTagIdTarget).find('option:selected').data('value-type');
    }
  }

  shouldShowEndDate() {
    if (this.registeredTagIdTarget) {
      return $(this.registeredTagIdTarget).find('option:selected').data('show-end-date') === true;
    }
    return false;
  }

  getSelectOptions() {
    if (this.registeredTagIdTarget) {
      const optionsString = $(this.registeredTagIdTarget).find('option:selected').data('value-options');
      if (optionsString && typeof optionsString === 'string') {
        return optionsString.split(',').map(option => option.trim());
      }
    }
    return [];
  }
}