import { consumer, isSubscribed } from "../consumer"
import { markDivAsConnected, markDivAsDisconnected, startLongPoller } from "../utility_functions";

document.addEventListener('subscribeToSchedule', (event) => {
  const { scheduleId, calendar } = event.detail;
  console.log("Received subscribeToSchedule event");
  subscribeToSchedule(scheduleId, calendar);
});

function subscribeToSchedule(scheduleId, calendar) {
  const channelName = getChannelName(scheduleId);
  const subscriptionObject = { channel: "EmrChannels::ScheduleChannel", channel_name: channelName };
  const indicatorId = `${scheduleId}-indicator`;

  let connected = false;
  let longPoller;

  if (!scheduleId) {
    console.error("No scheduleId provided to subscribeToSchedule");
    return;
  } else if (isSubscribed(subscriptionObject)) {
    longPoller = markDivAsConnected(indicatorId, scheduleId, longPoller);
    console.log("Already subscribed to schedule channel");
  } else {
    console.log("Attempting subscription to schedule channel");
    consumer.subscriptions.create(
      subscriptionObject,
      {
        connected() {
          console.log("Connected to schedule channel");
          connected = true;
          longPoller = markDivAsConnected(indicatorId, scheduleId, longPoller);
          // If the schedule was idle for a while we want to refetch changes in the interim
          // In fullcalendar.coffee we set data-last-fetched every time the last fetched is called
          const calendarElement = document.getElementById(scheduleId);
          const lastFetched = parseInt(calendarElement.getAttribute('data-last-fetched'), 10);
          const now = new Date().getTime();
          const threshold = 10 * 1000; // 10 seconds

          if (!lastFetched || now - lastFetched > threshold) {
            calendar.refetchEvents();
          }
        },
  
        disconnected() {
          console.log('disconnected from schedule channel');
          markDivAsDisconnected(indicatorId, scheduleId);
        },

        rejected() {
          console.log('rejected from schedule channel');
          markDivAsDisconnected(indicatorId, scheduleId);
        },
  
        received(data) {
          if (data.action === "create") {
            createAppointment(scheduleId, data);
          } else if (data.action === "update") {
            updateAppointment(scheduleId, data);
          } else if (data.action === "destroy") {
            deleteAppointment(scheduleId, data);
          }
        },

      }
    );

    console.log("Finished subscribing to schedule channel");

    setTimeout(() => {
      if (!connected) {
        // Connection hasn't been established within 60 seconds
        console.log("Connection timeout. Starting long poller...");
        startLongPoller(longPoller, () => refetchEvents(scheduleId));
      }
    }, 30000);
  }
}

function refetchEvents(scheduleId) {
  const calendar = window.calendarsMap.get(scheduleId);
  if (calendar) {
    calendar.refetchEvents();
  }
}

function createAppointment(scheduleId, data) {
  const calendar = window.calendarsMap.get(scheduleId);
  const appointment_data = data.appointment;
  const appointment = calendar.getEventById(appointment_data.id);
  if (!appointment) {
    calendar.addEvent(
      {
        id: appointment_data.id,
        title: appointment_data.title,
        editable: true,
        start: appointment_data.start_date,
        end:	appointment_data.end_date,
        backgroundColor: appointment_data.hex_color,
        borderColor: '#000',
        textColor: '#000',
        extendedProps: {
          icon: appointment_data.icon,
          patient_id: appointment_data.patient_id,
          position_id: appointment_data.position_id,
          appointment_type_name: appointment_data.appointment_type_name,
          identifier_code: appointment_data.identifier_code,
          patient_confirmed: appointment_data.patient_confirmed,
          status: appointment_data.status,
          sgid: appointment_data.sgid
        }
      },
      'appointments' // Source ID
    )
  }
}

function updateAppointment(scheduleId, data) {
  const calendar = window.calendarsMap.get(scheduleId);
  const appointment_data = data.appointment;
  const appointment = calendar.getEventById(appointment_data.id);
  if (appointment) {
    // Delete old appointment and add the new one
    // Doesn't trigger the "update" callback for each attribute we update
    appointment.remove();
    createAppointment(scheduleId, data);
  } else {
    // Note an appointment may be 'updated' by adding a preceptor or clinic_resource in which case
    // those calendars need to treat it as a new appointment
    createAppointment(scheduleId, data);
  }
}

function deleteAppointment(scheduleId, data) {
  const calendar = window.calendarsMap.get(scheduleId);
  const appointment_data = data.appointment;
  const appointment = calendar.getEventById(appointment_data.id);
  if (appointment) {
    appointment.remove();
  }
}

function getChannelName(scheduleId) {
  return `schedule_${scheduleId}`;
}

export default subscribeToSchedule;
