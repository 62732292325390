import {Controller as BaseController} from "@hotwired/stimulus";

export class Controller extends BaseController {
  connect() {}

  toggleComment(event) {
    const eyeIcon = event.currentTarget
    const eyeIconContainer = eyeIcon.closest('div')
    const commentDiv = eyeIconContainer.nextElementSibling

    commentDiv.classList.toggle('hidden')
    eyeIcon.classList.toggle('fa-eye')
    eyeIcon.classList.toggle('fa-eye-slash')
  }
}