import { Controller as BaseController } from "@hotwired/stimulus";
import $ from "jquery";

export class Controller extends BaseController {
  static targets = ["foldableCardContent", "dropDownButton"]
  static values = { open: Boolean }

  toggleFoldableCard() {
    if (this.foldableCardContentTarget.classList.contains("hidden-field")) {
      this.openFoldableCard()
    }
    else {
      this.closeFoldableCard()
    }
  }

  openFoldableCard() {
    this.dropDownButtonTarget.classList.add("foldable-card-rotate-180")
    $(this.foldableCardContentTarget).slideDown(200, () => {
        this.foldableCardContentTarget.classList.remove("hidden-field")
      });
  }

  closeFoldableCard() {
    $(this.foldableCardContentTarget).slideUp(200, () => {
      this.foldableCardContentTarget.classList.add("hidden-field")
    });
    this.dropDownButtonTarget.classList.remove("foldable-card-rotate-180")
  }
}