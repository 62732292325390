import { Controller as BaseController } from "@hotwired/stimulus";

export class Controller extends BaseController {
  static targets = ["menuItems", "trigger"]

  connect() {
    // For hover
    this.element.addEventListener('mouseenter', () => {
      this.openMenu();
      this.adjustMenuPosition();
    });
    
    this.element.addEventListener('mouseleave', () => {
      this.closeMenu();
    });
    
    // For click outside
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  disconnect() {
    this.element.removeEventListener('mouseenter', () => this.openMenu());
    this.element.removeEventListener('mouseleave', () => this.closeMenu());
    document.removeEventListener('click', this.handleClickOutside);
  }

  toggleMenu(event) {
    event.stopPropagation();
    const menu = this.menuItemsTarget;
    
    if (menu.style.display === 'flex') {
      this.closeMenu();
    } else {
      this.openMenu();
      this.adjustMenuPosition();
    }
  }

  openMenu() {
    this.menuItemsTarget.style.display = 'flex';
  }

  closeMenu() {
    this.menuItemsTarget.style.display = 'none';
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeMenu();
    }
  }

  adjustMenuPosition() {
    const menu = this.menuItemsTarget;
    const menuRect = menu.getBoundingClientRect();
    const viewportWidth = document.documentElement.clientWidth;

    // If menu extends beyond right viewport edge
    if (menuRect.right > viewportWidth) {
      const overflowAmount = menuRect.right - viewportWidth;
      menu.style.left = `${-overflowAmount}px`;
    }
    
    // If menu extends beyond left viewport edge
    if (menuRect.left < 0) {
      menu.style.left = '0px';
    }
  }
}